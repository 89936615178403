// Single static quote commented out - replaced with the carousel version 

// import React from 'react'
// import { colors } from '../common'

// export default () => (
//   <div css={`text-align:center;padding:2rem;`}>
//     <p css={`font-size:30px;`}><em><span css={`color:${colors.green}`}>"</span>Underwood’s may be the most discriminating diamond buyer <br />in the United States.<span css={`color:${colors.green}`}>"</span></em></p>
//     <p css={ `font-size:16px;`}>&mdash; MOSHE KLEIN, <strong css={`color:${colors.green}`}>JULIUS KLEIN DIAMONDS</strong></p>
//   </div>
// )

import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Slider from 'react-slick'
import './carousel.css'
import { md, colors } from '../common'

export default () => {
  const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 4000,
      slidesToShow: 1,
      slidesToScroll: 1,
      focusOnSelect: false,
      arrows: true
    }
  return(
  <StaticQuery
    query={graphql`
      query ReviewsQuery {
        allTestimonialsJson {
          edges {
            node {
              author
              company
              text
            }
          }
        }
      }
      `}
      render={ data => (
        <div css={`text-align:center;text-transform:uppercase;`}>
          <div css={`padding:2rem;display:none;${md}{display:block;}`}>
            <p css={`font-size:30px;`}><em><span css={`color:${colors.green}`}>"</span>Underwood’s may be the most discriminating diamond buyer <br />in the United States.<span css={`color:${colors.green}`}>"</span></em></p>
            <p css={`font-size:16px;`}>&mdash; MOSHE KLEIN, <strong css={`color:${colors.green}`}>JULIUS KLEIN DIAMONDS</strong></p>
          </div>
          <div css={`${md}{display:none}`}>
        <Slider css={`text-align:center;padding:2rem;`} {...settings} >
          {data.allTestimonialsJson.edges.map((person, index) => {
            return <div key={index}>
                    <p css={`font-size:30px;`}><em><span css={`color:${colors.green}`}>"</span>{person.node.text}<span css={`color:${colors.green}`}>"</span></em></p>
                    <p css={`font-size:16px;`}>&mdash; {person.node.author}{person.node.company ? <span>,<strong css={`color:${colors.green}`}> {person.node.company}</strong></span> : ''}</p>
                  </div>
          })}

        </Slider>
        </div>
        </div>
      )}
  />
)}
