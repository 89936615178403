import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import JoinUs from '../sections/joinUs'
import GreenBar from '../sections/greenBar4'
import Quote from '../sections/quote'
import { PageHead, colors, md } from '../common'

export default () => (
  <Layout>
    <SEO
      title="The Underwood Diamond"
      description="What sets the Underwood’s diamond apart from other jewelers? We buy for beauty and quality based on the cut of the diamond using Angular Spectrum Evaluation Tool technology. Learn more!" />
    <PageHead>
      <h1>THE UNDERWOOD DIAMOND</h1>
      <p><strong>This journey begins in a diamond cutter’s office with our inspection of rough diamonds partially faceted diamonds and fully finished cut diamonds.</strong></p>
      <p>First and foremost, diamonds are inspected for the quality of how they are cut.  While color, clarity and carat are defined by nature, cut is the only beauty factor of a diamond which is controlled by man and can affect the price of a diamond up to 50%. A diamond cutter will make more money if the diamond weighs one carat or more than if they cut the diamond just under a carat. This means a cutter will displace the weight to other areas of the diamond to make it weigh what it needs to in order to put more money in their pockets. The diamond’s light performance will then not be as brilliant as one that was cut for quality instead of weight. In other words, the diamond may technically weigh more, but not sparkle or look as big as one that was cut to ideal proportions.</p>
    </PageHead>
    <GreenBar />
    <div css={`padding:4rem 10rem;text-align:center;color:${colors.grey};${md}{padding:1rem;}`}>
      <h2>Underwood’s has three American Gem Society Certified Gemologists on staff, making them the most educated diamond buyers in Northeast Florida.</h2>
      <br />
      <p>Underwood’s Certified Gemologists, C. Clayton Bromberg and R. Michael Richards, are the only diamond buyers known by the diamond-cutting community to use the Angular Spectral Evaluation Tool (ASET) which allows the viewer to evaluate a diamond’s light performance (how much a diamond will sparkle). Once diamonds are selected for their quality of light performance they are sorted two more times:  first for color and second for clarity.   Underwood’s exacting standards are then verified by one of three diamond grading laboratories:  The Gemological Institute of America (GIA), the American Gem Society Laboratory (AGSL) or the Forevermark Diamond Institute (FMK). At this point these diamonds are sent to Jacksonville for final examination in our own American Gem Society (AGS) Accredited Gem Lab.</p>
    </div>
    <div css={`display:flex;justify-content:space-between;background-color:${colors.greyLight};padding: 2rem 0;${md}{flex-direction:column;}`}>
      <div css={`margin:auto;${md}{margin:2rem;}`}>
        <img css={`width:100%`} src="https://s3.amazonaws.com/underwoods/AmericanSociety.png" alt="american gem society logo" />
      </div>
      <div css={`margin:auto;`}>
        <img css={`width:100%`} src="https://s3.amazonaws.com/underwoods/Diamond+Education/chart-cut01.png" alt="diamond clarity split view" />
      </div>
      <div css={`margin:auto;${md}{margin:2rem;}`}>
        <img css={`width:100%`} src="https://s3.amazonaws.com/underwoods/Layer+40.png" alt="gia logo" />
      </div>
    </div>

    <div css={`display:flex;padding:2rem;${md}{flex-direction:column;}`}>
      <div css={`width:40%;padding:2rem;${md}{width:100%;padding:0;}${md}{width:100%;padding:0;}`}>

        <iframe title={'youtube video embed'} css={`width:100%;border:0;`} width="560" height="315" src="https://www.youtube.com/embed/_wxRRdLCYh8" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
      <div css={`width:50%;padding:2rem;text-align:center;margin:auto;line-height:20px;color:${colors.grey};${md}{width:100%;padding:0;}`}>
        <p>Once in the store in Jacksonville, FL the diamonds are cross-checked by our gemologists again. This confirmation of quality includes actually measuring for light performance using a computerized non-contact optical scanner that employs AGS performance grading software (measuring all nine factors that determine beauty in a diamond). Next, the diamond is color graded against color grading master diamonds and clarity graded using a binocular microscope. If the diamond measures up to the Underwood standards for light performance, color and clarity, it will be purchased for our inventory. We are the only store in Jacksonville with an accredited AGS gem lab who grades diamonds using ASET technology.</p>
      </div>
    </div>

    <div css={`display:flex;padding:0 2rem;${md}{flex-direction:column-reverse;}`}>
      <div css={`width:50%;padding:2rem;text-align:center;line-height:20px;color:${colors.grey};${md}{width:100%;padding:0;}`}>
        <p>If you wonder why we go to these extremes to purchase a diamond for our inventory, our answer is that we want every Underwood diamond to be brighter and more beautiful than any other diamond- not just in the greater Jacksonville area, but in the entire World.  We don’t leave this to chance; we use science to make it happen, employing every weapon in the gemological arsenal to make sure our diamonds are beautiful.</p>
        <p>Starting with our visit to the office of a diamond cutter and ending in our Accredited Gem Lab, we reject an average of 95 out of every 100 diamonds placed before us to purchase.  We take great pride in the distinction an Underwood diamond gives to the wearer, setting them apart.</p>
      </div>
      <div css={`width:40%;padding:2rem;${md}{width:100%;padding:0;}`}>
        <img css={`width:100%;`} src="https://s3.amazonaws.com/underwoods/diamond-tongs.jpg" alt="diamond" />
      </div>
    </div>

    <Quote />
    <JoinUs />
  </Layout>
)
